<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="none" v-model="searchParam.plantCd" @datachange="getList" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-datepicker
            label="대상년도"
            type="year"
            default="today"
            name="uninjuryYear"
            v-model="searchParam.uninjuryYear"
            @datachange="getList"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="무재해 달성이력"
      tableId="table"
      :merge="grid.merge"
      :columns="grid.columns"
      :data="grid.data"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      rowKey="uninjuryHistoryId"
      selection="multiple"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="지급완료 처리" icon="save" @btnClicked="saveDept('Y')" v-if="editable && grid.data.length > 0" />
          <c-btn label="미지급 처리" icon="remove" @btnClicked="saveDept('N')" color="orange" v-if="editable && grid.data.length > 0" />
          <c-btn label="삭제" icon="remove" @btnClicked="deleteDept" v-if="editable && grid.data.length > 0" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'uninjury-history',
  data() {
    return {
      searchParam: {
        plantCd: null,
        uninjuryYear: '',
      },
      grid: {
        merge: [
          { index: 0, colName: 'upDeptCd' },
          { index: 1, colName: 'upDeptCd' },
        ],
        columns: [
          {
            name: 'uninjuryYear',
            field: 'uninjuryYear',
            label: '년도',
            style: 'width:80px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'upDeptName',
            field: 'upDeptName',
            label: '상위부서',
            style: 'width:140px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            style: 'width:140px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'deptCnt',
            field: 'deptCnt',
            label: '인원',
            style: 'width:70px',
            align: 'right',
            type: 'cost',
            sortable: false,
          },
          {
            name: 'attainTerm',
            field: 'attainTerm',
            label: '무재해 기간',
            style: 'width:240px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'targetDay',
            field: 'targetDay',
            label: '달성일수',
            align: 'right',
            type: 'cost',
            style: 'width:140px',
            sortable: false,
          },
          {
            name: 'paymentFlagName',
            field: 'paymentFlagName',
            label: '지급여부',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
        ],
        data: [],
      },
      editable: true,
      headerUrl: '',
      listUrl: '',
      updateUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sai.uninjury.historys.url;
      this.updateUrl = transactionConfig.sai.uninjury.history.url
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    saveDept(_flag) {
      let selectData = this.$refs["table"].selected;
      this.$_.forEach(selectData, item => {
        item.editFlag = 'U'
        item.paymentFlag = _flag
      })
      if(selectData.length > 0) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '처리하시겠습니까?', 
          type: 'info',
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.updateUrl;
            this.$http.type = 'PUT';
            this.$http.param = selectData;
            this.$http.request(() => {
              this.getList();
              window.getApp.$emit('ALERT', {
                title: '안내', 
                message: '처리되었습니다.', 
                type: 'success', // success / info / warning / error
              });
              this.$refs['table'].$refs['compo-table'].clearSelection();
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    deleteDept() {
      let selectData = this.$refs["table"].selected;
      if(selectData.length > 0) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '무재해 달성이력을 삭제하시겠습니까?', 
          type: 'info',
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.updateUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            }
            this.$http.request(() => {
              this.getList();
              window.getApp.$emit('ALERT', {
                title: '안내', 
                message: '삭제되었습니다.', 
                type: 'success', // success / info / warning / error
              });
              this.$refs['table'].$refs['compo-table'].clearSelection();
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>